// export const firebase = {
//   apiKey: "AIzaSyBBFHdcQmTsZ3plrpBxCqM3jIBKSBJ3e44",
//   authDomain: "workflow-dev-3dc2e.firebaseapp.com",
//   databaseURL: "https://workflow-dev-3dc2e.firebaseio.com",
//   projectId: "workflow-dev-3dc2e",
//   storageBucket: "workflow-dev-3dc2e.appspot.com",
//   messagingSenderId: "912114457171",
//   appId: "1:912114457171:web:da58fa814b1404269c44a6"
// };

export const firebase = {
  apiKey: "AIzaSyDUbLEJr9hh_dtM5K9DeiMNIGJMAw95gU0",
  authDomain: "workflow-prod-35631.firebaseapp.com",
  databaseURL: "https://workflow-prod-35631.firebaseio.com",
  projectId: "workflow-prod-35631",
  storageBucket: "workflow-prod-35631.appspot.com",
  messagingSenderId: "114089697507",
  appId: "1:114089697507:web:ba19c62b2ae5b7b09e65d6"
};
