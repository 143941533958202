import React, { useState } from 'react';
import { Button, Input, Card, Modal } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import firebase from 'firebase/app';

export function ForgetPasswordForm({ showLogin }) {
  const [email, setEmail] = useState('');

  const onBackLogin = (evt) => {
    evt.preventDefault();

    showLogin();
  };

  return (
    <form onSubmit={e => {
      e.preventDefault();
      firebase.auth().sendPasswordResetEmail(email);
      Modal.info({
        title: "Email enviado!",
        content: `Verifique o email "${email}" e recupere sua senha.`,
        onOk() {
          setEmail('');
          showLogin();
        }
      });
    }}>
      <Card>
        <Card.Meta title="Workflow" description="Digie seu email e enviaremos a confirmação para o seu email."/>
        <Card bordered={false}>
          <Input
            type="email"
            name="username"
            size="large"
            placeholder="Email"
            prefix={<UserOutlined />}
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <div style={{ height: '10px' }}/>
          <a className="login-form-forgot" href="/" onClick={onBackLogin}>
            Voltar para o login
          </a>
        </Card>
        <Button type="primary" block shape="round" loading={false} htmlType="submit">Enviar</Button>
      </Card>
    </form>
  )
}
