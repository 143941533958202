import React from 'react';
import firebase from 'firebase/app';
import { Button, Dropdown, Modal, Menu } from 'antd';
import { UserOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

function HeaderUser() {
  const user = firebase.auth().currentUser;

  if(!user) {
    return null;
  }

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          Modal.confirm({
            title: 'Tem certeza que deseja sair?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
              firebase.auth().signOut();
              window.location.reload();
            },
          });
        }}
      >
        Sair
      </Menu.Item>
  </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <Button shape="round" icon={<UserOutlined />}>
        {user.displayName ? user.displayName : ''}
      </Button>
    </Dropdown>
  );
}

export default HeaderUser;
