import React, { useState, useEffect } from 'react'
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  @media screen and (min-width: 768px) {
    justify-content: flex-start;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 50px;
  overflow: hidden;
  margin-left auto;
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: auto;
`;

const FileUpload = styled.input`
  display: none;
`;

const Label = styled.label`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  max-width: 165px;
  width: 100%;
  height: 40px;
  color: #0d7757;
  font-size: 12px;
  font-weight: bold;
  border-radius: 30px;
  border: 2px solid #0d7757;

  &:active {
    color: #fff;
    background: #0d7757;
    border-color: #0d7757;
  }
`;

function UploadImage({ onChange }) {
  const [preview, setPreview] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if(!file || file instanceof Blob === false) { return; }

    const reader = new FileReader();
    reader.onload = function(e) {
      setPreview(reader.result);
    };

    reader.readAsDataURL(file);
  }, [file]);

  return (
    <Container>
      <Label htmlFor='image'>Marca do cliente</Label>
      <FileUpload type="file" id='image' onChange={function(e) {
        const file = e.currentTarget.files[0];
        setFile(file);
        onChange(file);
      }}/>
      <ImageContainer>
        {preview ? <Image src={preview} alt="Previsão"/> : null}
      </ImageContainer>
    </Container>
  );
}

UploadImage.defaultProps = {
  onChange: () => []
};

export default UploadImage;
