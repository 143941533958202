import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import Login from './pages/Login';
import Project from './pages/Project';
import Users from './pages/User';
import Clients from './pages/Clients';

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/cliente/:id">
          <Project />
        </Route>
        <Route exact path="/clientes">
          <Clients />
        </Route>
        <Route exact path="/usuarios">
          <Users />
        </Route>

        <Route path="/">
          <Login />
        </Route>

      </Switch>
    </Router>
  );
}
