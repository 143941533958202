import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import { Button, Modal, Input, Row, Col } from "antd";
import { PlusOutlined, LayoutOutlined } from "@ant-design/icons";
import styled from "styled-components";
import firebase from "firebase/app";
import withAuth from "../hoc/withAuth";
import HeaderUser from "../components/HeaderUser";
import HeaderSettings from "../components/HeaderSettings";
import UploadImage from "../components/UploadImage";
import slugfy from "limax";

const Container = styled.div`
  min-height: 100vh;
  background-color: #1e1e1c;
`;

const Header = styled.header`
  background-color: #ececec;
`;

const Page = styled.div`
  padding: 30px 10%;
`;

const Project = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  font-weight: bold;
  font-size: 32px;
  padding: 15px 30px;
  min-height: 200px;
  box-sizing: border-box;
  transition: transform 200ms ease;
  border-radius: 30px;

  &:hover {
    transform: scale(1.05);
    z-index: 5;
  }
`;

function useModal({ reload }) {
  const [isVisible, setIsVisible] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [image, setImage] = useState("");
  const [color, setColor] = useState("#1E1E1C");

  return {
    open: () => setIsVisible(true),
    close: () => setIsVisible(false),
    toggle: () => setIsVisible(!isVisible),
    state: isVisible,

    project: {
      name: projectName,
      setName: setProjectName,
      color,
      setColor,
      setImage,
      create: () => {
        const slug = slugfy(projectName);
        firebase
          .database()
          .ref("project/" + slug)
          .set({
            name: projectName,
            color,
            image,
            status: true,
            steps: {
              solicitacao: {
                title: "À fazer",
              },
              time: {
                title: "Em desenvolvimento",
              },
              revisao: {
                title: "Em Aprovação",
                col: {
                  tasks: { titulo: "", tasks: {} },
                },
              },
              arquivo: {
                title: "Arquivados",
                col: {
                  tasks: { titulo: "", tasks: {} },
                },
              },
            },
          });

        if (reload) {
          reload();
        }

        setIsVisible(false);
        setProjectName("");
        setColor("#1E1E1C");
      },
    },
  };
}

function useProjects() {
  const [done, setDone] = useState(false);
  const [projects, setProjects] = useState({});
  const [images, setImages] = useState({});

  function loadData() {
    const db = firebase.database().ref("project/");

    function readDb(snapshot) {
      const data = snapshot.val();
      setProjects(data);
    }

    db.once("value", readDb);
  }

  const updateImage = (id, url) => {
    setImages({ ...images, [id]: url });
  };

  if (!done && Object.keys(projects).length !== 0) {
    for (let projectId in projects) {
      const project = projects[projectId];

      if (images[projectId] === undefined) {
        if (!project.image) {
          updateImage(projectId, null);
        } else {
          firebase
            .storage()
            .ref(project.image)
            .getDownloadURL()
            .then((url) => {
              updateImage(projectId, url);
            })
            .catch((err) => {
              updateImage(projectId, null);
            });
        }
      }
    }
    console.log("is it done?");
    setDone(true);
  }

  return {
    projects,
    images,
    reload: loadData,
  };
}

function renderList(projects, images) {
  return (
    <Row gutter={[16, 16]}>
      {Object.keys(projects).map((key) => {
        const project = projects[key];

        if (!project.status) {
          return null;
        }

        return (
          <Col key={key} span={8}>
            <Project to={`/cliente/${key}`} color={project.color}>
              {project.name}
            </Project>
          </Col>
        );
      })}
    </Row>
  );
}

function Projects() {
  const [counter, setCounter] = useState(0);
  const { projects, reload, images } = useProjects();
  const newProjectModal = useModal({ reload });

  useEffect(() => {
    setTimeout(() => {
      reload();
      setCounter(counter + 1);
    }, 15 * 60 * 1000);

    reload();

    // eslint-disable-next-line
  }, [counter]);

  return (
    <Container>
      <Helmet>
        <title>Workflow</title>
      </Helmet>
      <Header>
        <div className="ant-page-header site-page-header ant-page-header-ghost">
          <div className="ant-page-header-heading">
            <div className="ant-page-header-heading-left">
              <span
                className="ant-page-header-heading-title"
                title="Workflow Phocus"
              >
                Workflow Phocus
              </span>
              <Button
                key="1"
                type="primary"
                shape="round"
                onClick={newProjectModal.open}
              >
                Novo Cliente <PlusOutlined />
              </Button>
            </div>
            <span className="ant-page-header-heading-extra">
              <HeaderSettings key="settings" />
              <HeaderUser key="user" />
            </span>
          </div>
        </div>
      </Header>

      <Page>{projects ? renderList(projects, images) : "Nenhum cliente"}</Page>

      <Modal
        title="Novo Cliente"
        visible={newProjectModal.state}
        onOk={newProjectModal.project.create}
        onCancel={newProjectModal.close}
        okText="Criar Cliente"
        cancelText="Fechar"
      >
        <UploadImage
          onChange={async (file) => {
            const ref = `logo/${file.name}`;
            firebase.storage().ref(ref).put(file);
            newProjectModal.project.setImage(ref);
          }}
        />
        <Input
          name="project"
          size="large"
          placeholder="Nome do projeto"
          prefix={<LayoutOutlined />}
          allowClear
          value={newProjectModal.project.name}
          onChange={(e) => newProjectModal.project.setName(e.target.value)}
        />
        Cor:
        <input
          type="color"
          value={newProjectModal.project.color}
          onChange={(e) => newProjectModal.project.setColor(e.target.value)}
          style={{ "margin-left": "15px", "margin-top": "15px" }}
        />
      </Modal>
    </Container>
  );
}

export default withAuth(Projects);
