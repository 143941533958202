
const adminEmail = [
  'fabio@maximize.com.br',
  'thiago@maximize.com.br',
  'ronaldo@maximize.com.br',
  'daniel@phocuspropaganda.com.br',
  'tiago@phocuspropaganda.com.br',
];


export default function isAdmin(user) {
  return (adminEmail.indexOf(user.email) !== -1);
}
