import React from 'react';
import firebase from 'firebase/app';
import { Link } from 'react-router-dom';
import isAdmin from '../isAdmin';

function HeaderSettings() {
  const user = firebase.auth().currentUser;

  if(!user) {
    return null;
  }

  if(!isAdmin(user)) {
    return null;
  }

  return [
    <Link className="ant-dropdown-link" to="/clientes" key="project">
      Clientes
    </Link>,
    <Link className="ant-dropdown-link" to="/usuarios" key="user">
      Usuários
    </Link>
  ]
}

export default HeaderSettings;
