
const CHANGE_USER = 'auth/CHANGE_USER';
const LOGOUT = 'auth/LOGOUT';

const INITIAL = {
  user: null
};

export default (state = INITIAL, action) => {
  switch(action.type) {

    case CHANGE_USER:
      return {
        ...state,
        user: action.payload
      };

    case LOGOUT:
      return {
        ...state,
        user: null
      };

    default:
      return state;
  }
};

export const setUser = user => {
  return { type: CHANGE_USER, payload: user };
};

export const logout = () => {
  return { type: LOGOUT }
};
