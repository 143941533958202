import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import Login from '../pages/Login';

const withAuth = (Component) => props => {
  const [user, setUser] = useState(firebase.auth().currentUser);

  useEffect(() => {
    setUser(firebase.auth().currentUser);

    firebase.auth().onAuthStateChanged(user => {
      setUser(user);
    });
  }, []);

  if(user !== null) {
    return (
      <Component user={user} {...props} />
    );
  }

  return <Login />
}

export default withAuth;
