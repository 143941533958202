import React, { useEffect, useState } from "react";
import { useParams, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Spin, Input } from "antd";
import Board from "./project/Board";
import HeaderUser from "../components/HeaderUser";
import styled from "styled-components";
import firebase from "firebase/app";
import { useImage } from "../hooks/useImage";

const { Search } = Input;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${(props) => (props.color ? props.color : "#1E1E1C")};
`;

const Header = styled.header`
  background-color: #ececec;
`;

const Page = styled.div`
  display: flex;
  padding: 15px;
  flex: 1;
  box-sizing: border-box;
`;

const SpinContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 200px;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 50px;
  overflow: hidden;
  margin-left auto;
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: auto;
`;

function useProject({ id }) {
  const [project, setProject] = useState(null);

  useEffect(() => {
    const projectRef = firebase.database().ref(`project/${id}`);

    function update(snapshot) {
      setProject(snapshot.val());
    }

    projectRef.on("value", update);
  }, [id]);

  return {
    project,
  };
}

function Project() {
  const [search, setSearch] = useState("");
  const { id } = useParams();
  const { project } = useProject({ id });
  const projectName = project !== null ? project.name : "Carregando!";
  const img = useImage(project !== null ? project.image : null);

  if (project !== null && !project.status) {
    return <Redirect to="/" />;
  }

  return (
    <Container color={project ? project.color : null}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{projectName} | Workflow</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      {project !== null ? (
        <Header>
          <div className="ant-page-header" title={projectName}>
            <div className="ant-page-header-heading">
              <div className="ant-page-header-heading-left">
                {img === null || project.image === "" ? (
                  <span
                    className="ant-page-header-heading-title"
                    title={projectName}
                  >
                    {projectName}
                  </span>
                ) : null}
                {img === undefined && project.image !== "" ? (
                  <span
                    className="ant-page-header-heading-title"
                    title="Carregando..."
                  >
                    Carregando...
                  </span>
                ) : null}
                {img !== null && img !== undefined ? (
                  <ImageContainer>
                    <Image src={img} alt={projectName} />
                  </ImageContainer>
                ) : null}

                <Search
                  placeholder="Buscar no quadro"
                  allowClear
                  onChange={({ target }) => setSearch(target.value)}
                  value={search}
                  style={{ width: 200 }}
                />
              </div>
              <span className="ant-page-header-heading-extra">
                <HeaderUser key="user" />
              </span>
            </div>
          </div>
        </Header>
      ) : null}

      <Page>
        {project === null ? (
          <SpinContainer>
            <Spin />
          </SpinContainer>
        ) : (
          <Board project={project} id={id} search={search} />
        )}
      </Page>
    </Container>
  );
}

export default Project;
