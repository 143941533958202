import React, { useState } from "react";
import styled from "styled-components";
import {
  Card,
  Rate,
  Button,
  Modal,
  Input,
  Menu,
  Dropdown,
  Descriptions,
  DatePicker,
  Checkbox,
} from "antd";
import {
  PlusOutlined,
  UnorderedListOutlined,
  EllipsisOutlined,
  EditOutlined,
  NumberOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import moment from "moment";
import firebase from "firebase/app";
import "./Task.css";

const Column = styled.div`
  position: relative;
  flex: 1;
  padding: 50px 10px 10px 10px;
  margin-right: 10px;
  border-radius: 3px;
  box-sizing: border-box;
  background-color: #ccc;
  min-height: 350px;
`;

const ColTitle = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
  width: calc(100% - 20px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8em;
  font-weight: 600;
`;

const TaskContainer = styled.div`
  max-height: 350px;
  overflow: auto;
`;

const prioridadesLabel = [
  "Mínima (Azul)",
  "Baixa (Verde)",
  "Média (Amarelo)",
  "Alta (Laranja)",
  "Máxima (Vermelho)",
];

function createTask() {
  return {
    number: "",
    title: "",
    description: "",
    priority: 0,
    data: "",
    isSpecial: false,
  };
}

function useAddModal({ projectId, stepKey, id }) {
  const [isVisible, setIsVisible] = useState(false);
  const [updateId, setUpdateId] = useState(null);
  const [task, setTask] = useState(createTask());

  function saveNewTask(task) {
    firebase
      .database()
      .ref(`project/${projectId}/steps/${stepKey}/col/${id}/tasks`)
      .push({
        ...task,
        creadtedAt: new Date(),
        updatedAt: new Date(),
      });

    setIsVisible(false);
    setTask(createTask());
  }

  function updateTask(task_id, task) {
    firebase
      .database()
      .ref(`project/${projectId}/steps/${stepKey}/col/${id}/tasks/${task_id}`)
      .set({
        ...task,
        updatedAt: new Date(),
      });

    setIsVisible(false);
    setTask(createTask());
  }

  return {
    open: (id = null, task = createTask()) => {
      setIsVisible(true);
      setUpdateId(id);
      setTask(task);
    },
    close: () => setIsVisible(false),
    state: isVisible,
    submit: () => {
      if (!task.data) {
        task.data = moment();
      }

      const formatedTask = {
        ...task,
        data: task.data.format ? task.data.format("DD/MM/YYYY") : task.data,
      };

      if (updateId === null) {
        saveNewTask(formatedTask);
      } else {
        updateTask(updateId, formatedTask);
      }
    },
    task,
    updateValue: (key, value) => {
      setTask({
        ...task,
        [key]: value,
      });
    },
  };
}

async function moveItem(from, to) {
  const db = firebase.database();
  const taskSnapshot = await db.ref(from).once("value");

  const task = taskSnapshot.val();
  task.updatedAt = new Date();

  db.ref(from).set(null);
  db.ref(to).set(task);
}

async function deleteItem(address) {
  firebase.database().ref(address).remove();
}

function extractColsList({ project, projectId }) {
  const stepsIds = Object.keys(project.steps);
  const steps = stepsIds.map((stepId) => {
    const step = project.steps[stepId];
    const cols = step.col
      ? Object.keys(step.col).map((colId) => {
          const col = step.col[colId];

          return {
            id: colId,
            name: col.name,
            address: `/project/${projectId}/steps/${stepId}/col/${colId}`,
          };
        })
      : [];

    return {
      id: stepId,
      name: step.title,
      cols,
    };
  });

  return steps;
}

function Col({
  id,
  col,
  projectId,
  project,
  stepKey,
  editCol,
  hasAddColumn,
  search,
}) {
  const modal = useAddModal({ projectId, stepKey, id });
  const possibleCols = extractColsList({ project, projectId });
  const user = firebase.auth().currentUser;

  return (
    <Column key={id}>
      <ColTitle>
        {(
          <span>
            {col.name}{" "}
            {user !== null && hasAddColumn ? (
              // eslint-disable-next-line
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  editCol();
                }}
              >
                <EditOutlined />
              </a>
            ) : null}
          </span>
        ) || " "}

        {user !== null ? (
          <Button shape="circle" onClick={() => modal.open(null)}>
            <PlusOutlined />
          </Button>
        ) : null}
      </ColTitle>

      <TaskContainer>
        {col.tasks
          ? Object.keys(col.tasks)
              .map((taskId) => {
                const task = col.tasks[taskId];
                task.id = taskId;
                return task;
              })
              .filter((task) => {
                if (search === "" || !search) {
                  return true;
                }

                const { number, title, atendimento, data } = task;

                const hasNumber =
                  !!number &&
                  number.toLowerCase().includes(search.toLowerCase());
                const hasTitle =
                  !!title && title.toLowerCase().includes(search.toLowerCase());
                const hasAtendimento =
                  !!atendimento &&
                  atendimento?.toLowerCase().includes(search.toLowerCase());
                const hasData =
                  !!data && data.toLowerCase().includes(search.toLowerCase());

                return hasNumber || hasTitle || hasAtendimento || hasData;
              })
              .sort((a, b) => b.priority - a.priority)
              .map((task) => {
                const menu = (
                  <Menu>
                    <Menu.Item
                      title="Editar"
                      onClick={() => modal.open(task.id, task)}
                    >
                      Editar
                    </Menu.Item>
                    <Menu.ItemGroup title="Mover Para">
                      {possibleCols.map((movableStep) => (
                        <Menu.SubMenu
                          title={movableStep.name}
                          key={movableStep.id}
                        >
                          {movableStep.cols.map((movableCol) => (
                            <Menu.Item
                              key={movableCol.id}
                              onClick={() => {
                                const address = `/project/${projectId}/steps/${stepKey}/col/${id}/tasks/${task.id}`;
                                moveItem(
                                  address,
                                  movableCol.address + `/tasks/${task.id}`
                                );
                              }}
                            >
                              {movableCol.name
                                ? movableCol.name
                                : movableStep.name}
                            </Menu.Item>
                          ))}
                        </Menu.SubMenu>
                      ))}
                    </Menu.ItemGroup>
                    <Menu.Item
                      title="Excluir"
                      onClick={() => {
                        const address = `/project/${projectId}/steps/${stepKey}/col/${id}/tasks/${task.id}`;
                        excluirClick(task, address);
                      }}
                    >
                      Excluir
                    </Menu.Item>
                  </Menu>
                );

                const actions =
                  user !== null ? (
                    <div className="Task__actions">
                      <Dropdown key="move" overlay={menu}>
                        <EllipsisOutlined onClick={(e) => e.preventDefault()} />
                      </Dropdown>
                    </div>
                  ) : null;

                const seeMoreClick = (e) => {
                  e.preventDefault();
                  Modal.info({
                    title: "Detalhes",
                    content: (
                      <Descriptions column={1}>
                        <Descriptions.Item label="Job">
                          {task.number ? `[${task.number}] ` : ""}
                          {task.title}
                        </Descriptions.Item>
                        <Descriptions.Item label="Atendimento">
                          {task.atendimento}
                        </Descriptions.Item>
                        <Descriptions.Item label="Solicitante">
                          {task.solicitante}
                        </Descriptions.Item>
                        <Descriptions.Item label="Descrição">
                          {task.description}
                        </Descriptions.Item>
                        <Descriptions.Item label="Data de entrega">
                          {task.data}
                        </Descriptions.Item>
                        <Descriptions.Item label="Prioridade">
                          <Rate
                            tooltips={prioridadesLabel}
                            value={task.priority}
                            disabled
                          />
                        </Descriptions.Item>
                      </Descriptions>
                    ),
                    onOk() {},
                  });
                };

                const excluirClick = (task, address) => {
                  Modal.confirm({
                    title: "Apagar job?",
                    icon: <ExclamationCircleOutlined />,
                    content: `Apagar "${task.title}" para sempre?`,
                    okText: "Apagar",
                    cancelText: "Cancelar",
                    async onOk() {
                      deleteItem(address);
                    },
                  });
                };

                const isSpecial = !!task.isSpecial;

                return (
                  <Card
                    key={task.id}
                    className={[
                      "Task",
                      `Task--priority-${task.priority}`,
                      `Task--is-special-${isSpecial}`,
                    ].join(" ")}
                  >
                    {/* eslint-disable-next-line */}
                    <a href="#" className="Task__title" onClick={seeMoreClick}>
                      {task.number ? `[${task.number}] ` : ""}
                      {task.title}
                    </a>
                    {actions}
                  </Card>
                );
              })
          : null}
      </TaskContainer>

      <Modal
        title={col.name ? `Job (${col.name})` : "Job"}
        visible={modal.state}
        onOk={modal.submit}
        onCancel={modal.close}
        okText="Enviar"
        cancelText="Fechar"
      >
        <Checkbox
          name="especial"
          placeholder="Job Urgente (fora do prazo da SLA)"
          checked={modal.task.isSpecial}
          onChange={(e) => modal.updateValue("isSpecial", e.target.checked)}
        >
          Job Urgente (fora do prazo da SLA)
        </Checkbox>
        <br />
        <br />
        <Input
          name="number"
          placeholder="Número do Job"
          type="number"
          prefix={<NumberOutlined />}
          allowClear
          value={modal.task.number}
          onChange={(e) => modal.updateValue("number", e.target.value)}
        />
        <br />
        <br />
        <Input
          name="column"
          size="large"
          placeholder="Job"
          prefix={<UnorderedListOutlined />}
          allowClear
          value={modal.task.title}
          onChange={(e) => modal.updateValue("title", e.target.value)}
        />
        <br />
        <br />

        <DatePicker
          type="datetime"
          name="column"
          size="large"
          placeholder="Data de entrega"
          allowClear
          format="DD/MM/YYYY"
          defaultValue={moment()}
          value={
            typeof modal.task.data === "string"
              ? modal.task.data === ""
                ? moment()
                : moment(modal.task.data, "DD/MM/YYYY")
              : modal.task.data
          }
          onChange={(data) => modal.updateValue("data", data)}
        />
        <br />
        <br />
        <Input
          name="column"
          size="large"
          placeholder="Atendimento"
          prefix={<UserOutlined />}
          allowClear
          value={modal.task.atendimento}
          onChange={(e) => modal.updateValue("atendimento", e.target.value)}
        />
        <br />
        <br />
        <Input
          name="column"
          size="large"
          placeholder="Solicitante"
          prefix={<UserOutlined />}
          allowClear
          value={modal.task.solicitante}
          onChange={(e) => modal.updateValue("solicitante", e.target.value)}
        />
        <br />
        <br />
        <Input.TextArea
          name="column"
          size="large"
          placeholder="Descrição do Job"
          prefix={<UnorderedListOutlined />}
          allowClear
          autoSize
          value={modal.task.description}
          onChange={(e) => modal.updateValue("description", e.target.value)}
        />
        <br />
        <br />
        <label>Prioridade:</label>
        <br />
        <Rate
          tooltips={prioridadesLabel}
          onChange={(v) => modal.updateValue("priority", v)}
          value={modal.task.priority}
        />
      </Modal>
    </Column>
  );
}

export default Col;
