import React from 'react';
import { Button, Input, Card } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useAuth } from '../../hooks/useAuth';

export function LoginForm({ showForget }) {
  const auth = useAuth();

  const onForgetPasswordClick = (evt) => {
    evt.preventDefault();

    showForget();
  };

  return (
    <form onSubmit={e => {
      e.preventDefault();
      auth.submit();
    }}>
      <Card>
        <Card.Meta title="Workflow" description="Digite seu email e sua senha"/>
        <Card bordered={false}>
          <Input
            type="email"
            name="username"
            size="large"
            placeholder="Email"
            prefix={<UserOutlined />}
            value={auth.credentials.email}
            onChange={e => auth.credentials.setEmail(e.target.value)}
          />
          <br /><br/>
          <Input
            type="password"
            name="password"
            size="large"
            placeholder="Senha"
            prefix={<LockOutlined />}
            value={auth.credentials.password}
            onChange={e => auth.credentials.setPassword(e.target.value)}
          />
          <div style={{ height: '10px' }}/>
          <a className="login-form-forgot" href="/" onClick={onForgetPasswordClick}>
            Esqueci a senha
          </a>
        </Card>
        <Button type="primary" block shape="round" loading={auth.isLoading} htmlType="submit">Entrar</Button>
      </Card>
    </form>
  )
}
