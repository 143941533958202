import React, { useState, useEffect } from 'react';
import { Button, Modal, Input } from 'antd';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import { PlusOutlined, UserOutlined, MailOutlined, LockOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import axios from 'axios';
import firebase from 'firebase/app';
import withAuth from '../hoc/withAuth';
import HeaderUser from '../components/HeaderUser';
import HeaderSettings from '../components/HeaderSettings';
import isAdmin from '../isAdmin';

const Container = styled.div`
  min-height: 100vh;
  background-color: #1E1E1C;
`;

const Header = styled.header`
  background-color: #ECECEC;
`;

const Page = styled.div`
  padding: 30px 10%;
`;

const User = styled.div`
  padding: 15px 13px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
`
const UserActions = styled.div`

`;

function useUsers() {
  const [done,] = useState(true);
  const [users, setUsers] = useState();

  function reloadUsers() {
    axios.get('/api/user')
      .then(result => {
        setUsers(result.data);
      });
  }

  useEffect(() => {
    reloadUsers();
  }, [done]);

  return {
    users,
    reloadUsers,
    deleteUser: ({ uid, email }) => {
      Modal.confirm({
        title: 'Apagar usuário?',
        icon: <ExclamationCircleOutlined />,
        content: `Apagar "${email}" para sempre?`,
        okText: 'Apagar',
        cancelText: 'Cancelar',
        async onOk() {
          await axios.delete('/api/user/'+ uid);
          setUsers(users.filter(user => user.uid !== uid));
        },
      });
    },
    resetPassword(email) {
      firebase.auth().sendPasswordResetEmail(email);
      Modal.info({
        title: 'Esqueceu a senha',
        content: `Email enviado!`,
      });
    }
  }
}

function useModal({ reload }) {
  const [isVisible, setIsVisible] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');

  return {
    open: () => setIsVisible(true),
    close: () => setIsVisible(false),
    toggle: () => setIsVisible(!isVisible),
    state: isVisible,

    user: {
      displayName,
      setDisplayName,
      email: userEmail,
      setEmail: setUserEmail,

      password: userPassword,
      setPassword: setUserPassword,

      async create() {
        axios.post('/api/user', {
          displayName,
          email: userEmail,
          password: userPassword,
        }).then(() => {
          setIsVisible(false);

          if(reload)
            reload();

          setDisplayName('');
          setUserEmail('');
          setUserPassword('');
        })
        .catch(() => {
          Modal.error({
            title: 'Erro!',
            content: 'Não foi possível criar esse usário. Tente novamente mais tarde.',
            okText: 'Ok',
          });
        });

      }
    }
  }
};

function Projects() {
  const { users, deleteUser, reloadUsers, resetPassword } = useUsers();
  const modal = useModal({ reload: reloadUsers });
  const { currentUser } = firebase.auth();

  if(currentUser) {
    if (!isAdmin(currentUser)) {
      return;
    }
  }

  return (
    <Container>
      <Helmet>
        <title>Usuários | Workflow</title>
      </Helmet>
      <Header>
        <div className="ant-page-header site-page-header ant-page-header-ghost">
        <div className="ant-page-header-heading">
          <div className="ant-page-header-heading-left">
            <Link to="/" className="ant-page-header-heading-title">Workflow Phocus</Link>
            <span className="ant-page-header-heading-title">/ Usuários</span>

            <Button key="1" type="primary" shape="round" onClick={modal.open}>
              Novo usuário <PlusOutlined />
            </Button>
          </div>
          <span className="ant-page-header-heading-extra">
            <HeaderSettings key="settings" />
            <HeaderUser key="user" />
          </span>
        </div>
      </div>
      </Header>

      <Page>
      {users ? users.map(user => (
        <User key={user.uid}>
          <a href={`mailto:${user.email}`}>{user.displayName ? user.displayName : user.email}</a>

          <UserActions>
            {currentUser && currentUser.uid !== user.uid ? <a href={`/${user.uid}`} onClick={e => {
              e.preventDefault();

              deleteUser(user);
            }} className="ant-link" style={{ marginRight: '10px' }}>Apagar</a> : null}
            {' '}
            <a href={`/${user.uid}`} onClick={e => {
              e.preventDefault();

              resetPassword(user.email);
            }} className="ant-link">Esqueceu a senha</a>
          </UserActions>
        </User>
      )) : null}

        <Modal
          title="Novo Usuário"
          visible={modal.state}
          onOk={modal.user.create}
          onCancel={modal.close}
          okText="Criar Usuário"
          cancelText="Fechar"
        >
          <Input
            type="text"
            name="displayName"
            size="large"
            placeholder="Nome"
            prefix={<UserOutlined />}
            allowClear
            value={modal.user.displayName}
            onChange={e => modal.user.setDisplayName(e.target.value)}
          /><br/><br/>
          <Input
            type="email"
            name="email"
            size="large"
            placeholder="Email"
            prefix={<MailOutlined />}
            allowClear
            value={modal.user.email}
            onChange={e => modal.user.setEmail(e.target.value)}
          /><br/><br/>
          <Input
            type="password"
            name="password"
            size="large"
            placeholder="Senha"
            prefix={<LockOutlined />}
            allowClear
            value={modal.user.password}
            onChange={e => modal.user.setPassword(e.target.value)}
          />
        </Modal>
      </Page>
    </Container>
  )
};

export default withAuth(Projects);
