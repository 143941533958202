import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import { Modal } from 'antd';
import styled from 'styled-components';
import firebase from 'firebase/app';
import withAuth from '../hoc/withAuth';
import HeaderUser from '../components/HeaderUser';
import HeaderSettings from '../components/HeaderSettings';
import UploadImage from '../components/UploadImage';

const Container = styled.div`
  min-height: 100vh;
  background-color: #1E1E1C;
`;

const Header = styled.header`
  background-color: #ECECEC;
`;

const Page = styled.div`
  padding: 30px 10%;
`;

const Client = styled.div`
  padding: 15px 13px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
`
const ClientActions = styled.div`

`;


function useProjects() {
  const [projects, setProjects] = useState({});

  function loadData() {
    const db = firebase.database().ref('project/');

    function readDb(snapshot) {
      const data = snapshot.val();
      setProjects(data);
    };

    db.once('value', readDb);
  }

  return {
    reload: loadData,
    projects,
    toggleStatus: async (uid, status) => {
      await firebase.database().ref(`project/${uid}/status`).set(status);
      loadData();
    }
  };
}

function renderList(projects, toggleStatus, image) {
  return Object.keys(projects).map(key => {
    const project = projects[key];

    return (
      <Client color={project.color} key={key}>
        <Link to={`/cliente/${key}`}>{project.name}</Link>

        <ClientActions>
          <a href={`/cliente/${key}`} onClick={e => {
            e.preventDefault();
            image.setModalVisible(key);

            }}>Trocar a marca</a>
          &nbsp;|&nbsp;
          <a href={`/cliente/${key}`} onClick={e => {
            e.preventDefault();
            toggleStatus(key, !project.status);

          }}>{project.status ? 'Desativar' : 'Ativar'}</a>
        </ClientActions>
      </Client>
    );
  });
}

function Projects() {
  const [counter, setCounter] = useState(0);
  const [isModalVisible, setModalVisible] = useState('');
  const [image, setImage] = useState('');
  const { projects, reload, toggleStatus, changeImage } = useProjects();

  useEffect(() => {
    setTimeout(() => {
      reload();
      setCounter(counter + 1);
    }, 15 * 60 * 1000);
    reload();
    // eslint-disable-next-line
  }, [counter]);

  return (
    <Container>
      <Helmet>
        <title>Clientes | Workflow</title>
      </Helmet>
      <Header>
      <div className="ant-page-header site-page-header ant-page-header-ghost">
        <div className="ant-page-header-heading">
          <div className="ant-page-header-heading-left">
            <Link to="/" className="ant-page-header-heading-title" title="Clientes">Workflow Phocus</Link>
            <span className="ant-page-header-heading-title">/ Clientes</span>
          </div>
          <span className="ant-page-header-heading-extra">
            <HeaderSettings key="settings" />
            <HeaderUser key="user" />
          </span>
        </div>
      </div>
      </Header>

      <Page>
        {projects ? renderList(projects, toggleStatus, { changeImage, setModalVisible }) : 'Nenhum cliente'}

        <Modal
          title="Trocar a marca"
          visible={!!isModalVisible}
          onOk={async () => {
            const ref = `logo/${image.name}`;
            firebase.storage().ref(ref).put(image);
            firebase.database().ref(`project/${isModalVisible}/image`).set(ref);

            setImage('');
            setModalVisible('');
          }}
          onCancel={() => setModalVisible('')}
        >
          <UploadImage onChange={setImage}/>
        </Modal>
      </Page>
    </Container>
  )
};

export default withAuth(Projects);
