import { useState, useEffect } from 'react';
import firebase from 'firebase/app';

export function useAuth() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [user, setUser] = useState(firebase.auth().currentUser);

  const submit = async () => {
    setLoading(true);

    try {
      const authenticaation = await firebase.auth().signInWithEmailAndPassword(email, password)
      setUser(authenticaation.user);
    } catch(err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setUser(firebase.auth().currentUser);

    firebase.auth().onAuthStateChanged(user => {
      if(user !== null) {
        setUser(user);
      }
    });
  }, []);

  return {
    credentials: { email, password, setEmail, setPassword },
    isLoading,
    submit,
    user
  }
}
