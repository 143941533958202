import React, { useState } from 'react';
import styled from 'styled-components';
import { useAuth } from '../hooks/useAuth';
import { LoginForm } from './login/LoginForm';
import { ForgetPasswordForm } from './login/ForgetPasswordForm';
import Projects from './Projects';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #1E1E1C;
`;


export default function Login() {
  const [isForget, setForget] = useState(false);
  const auth = useAuth();

  if(auth.user) {
    return <Projects />;
  }

  return (
    <Container>
      { 
        isForget 
        ? <ForgetPasswordForm showLogin={() => setForget(false)}/> 
        : <LoginForm showForget={() => setForget(true)}/>
      }
    </Container>
  );
};

