import React from "react";
import styled from "styled-components";
import Step from "./Step";

const Grid = styled.main`
  display: grid;
  grid-template-columns: 6fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 16px;
  flex: 1;
`;

function Board({ project, id, search }) {
  return (
    <Grid>
      <Step
        projectId={id}
        project={project}
        step={project.steps.time}
        stepKey={"time"}
        search={search}
      />
      <Step
        projectId={id}
        project={project}
        step={project.steps.revisao}
        stepKey={"revisao"}
        hasAddColumn={false}
        search={search}
      />
      <Step
        projectId={id}
        project={project}
        step={project.steps.solicitacao}
        stepKey={"solicitacao"}
        search={search}
      />
      <Step
        projectId={id}
        project={project}
        step={project.steps.arquivo}
        stepKey={"arquivo"}
        hasAddColumn={false}
        search={search}
      />
    </Grid>
  );
}

export default Board;
