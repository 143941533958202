import { useState, useEffect } from 'react';
import firebase from 'firebase/app';

export function useImage(ref) {
  const [img, setImg] = useState(undefined);

  useEffect(() => {
    if(ref) {
      firebase.storage().ref(ref).getDownloadURL()
        .then(setImg)
        .catch(err => {
          setImg(null);

          console.log(err);
        });
    }
  }, [ref]);

  return img;
}
