import React, { useState } from "react";
import styled from "styled-components";
import { Card, Button, Modal, Input } from "antd";
import { PlusOutlined, UnorderedListOutlined } from "@ant-design/icons";
import firebase from "firebase/app";
import Col from "./Col";
import "./Step.css";

const Row = styled.div`
  display: flex;
`;

const modes = {
  EDIT: "edit",
  CREATE: "create",
};

function useModal({ projectId, stepKey }) {
  const [editId, setEditId] = useState(null);
  const [mode, setMode] = useState(modes.CREATE);
  const [isVisible, setIsVisible] = useState(false);
  const [name, setName] = useState("");

  return {
    open: (mode = modes.CREATE, defaultName = "", id = null) => {
      setIsVisible(true);
      setMode(mode);
      setName(defaultName);
      setEditId(id);
    },
    close: () => setIsVisible(false),
    toggle: () => setIsVisible(!isVisible),
    state: isVisible,
    mode,
    column: {
      name,
      setName,
      create: () => {
        firebase
          .database()
          .ref(`project/${projectId}/steps/${stepKey}/col/`)
          .push({
            name,
            tasks: {},
          });

        setIsVisible(false);
        setName("");
      },
      update: async () => {
        const updateRef = firebase
          .database()
          .ref(`project/${projectId}/steps/${stepKey}/col/${editId}`);
        const snapshot = await updateRef.once("value");

        updateRef.set({ ...snapshot.val(), name });

        setIsVisible(false);
        setName("");
      },
      fullDelete: async () => {
        Modal.confirm({
          title: "Tem certeza que deseja apagar?",
          content:
            "Apagando essa columna você está apagando todos os jobs que estão nela.",
          okText: "APAGAR COMPLETAMENTE",
          onOk() {
            const updateRef = firebase
              .database()
              .ref(`project/${projectId}/steps/${stepKey}/col/${editId}`);
            updateRef.set(null);
          },
        });
      },
    },
  };
}

function Step({ project, projectId, stepKey, step, hasAddColumn, search }) {
  const { col } = step;
  const modal = useModal({ projectId, stepKey });
  const user = firebase.auth().currentUser;

  return (
    <Card title={step.title} className="Step">
      {col ? (
        <Row>
          {Object.keys(col).map((id) => (
            <Col
              key={id}
              id={id}
              col={col[id]}
              project={project}
              projectId={projectId}
              stepKey={stepKey}
              editCol={() => modal.open(modes.EDIT, col[id].name, id)}
              hasAddColumn={hasAddColumn}
              search={search}
            />
          ))}
        </Row>
      ) : (
        "Sem Jobs :("
      )}

      {hasAddColumn && user !== null ? (
        <Button
          type="primary"
          shape="circle"
          className="Step__add"
          onClick={() => {
            modal.open(modes.CREATE);
          }}
        >
          <PlusOutlined />
        </Button>
      ) : null}

      <Modal
        title={
          modal.mode === modes.CREATE
            ? `Nova coluna (${step.title})`
            : `Editar coluna (${step.title})`
        }
        visible={modal.state}
        onOk={
          modal.mode === modes.CREATE
            ? modal.column.create
            : modal.column.update
        }
        onCancel={modal.close}
        okText={modal.mode === modes.CREATE ? "Criar Coluna" : "Editar"}
        cancelText="Fechar"
      >
        <Input
          name="column"
          size="large"
          placeholder="Nome da coluna"
          prefix={<UnorderedListOutlined />}
          allowClear
          value={modal.column.name}
          onChange={(e) => modal.column.setName(e.target.value)}
        />
        <br /> <br />
        {modal.mode === modes.EDIT ? (
          <Button
            type="primary"
            danger
            onClick={(e) => {
              e.preventDefault();
              modal.column.fullDelete();
            }}
          >
            Apagar permanentemente
          </Button>
        ) : null}
      </Modal>
    </Card>
  );
}

Step.defaultProps = {
  hasAddColumn: true,
};

export default Step;
